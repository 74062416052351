export const appConstants = {
    // Flash
    // prodApiBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:8080',
    prodApiBaseUrl: 'https://leads.backend.upwards.in',
    // prodApiBaseUrl: 'https://lms.backend.upwards.in',
    // prodApiBaseUrl: 'http://uat1.upwards.in',
    // prodApiBaseUrl: 'http://ec2-35-154-22-238.ap-south-1.compute.amazonaws.com:8000',
    // prodApiBaseUrl: 'http://ec2-13-233-128-242.ap-south-1.compute.amazonaws.com:8000',
    // batman
    // prodApiBaseUrl: 'http://ec2-13-126-207-236.ap-south-1.compute.amazonaws.com:8080',
    // prodApiBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:8000',

    version: '1.8.9',
    regexes: {
        pan: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
        email: /([a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256})(\@)([a-zA-Z0-9][a-zA-Z0-9\\-]{0,64})(\.)([a-zA-Z0-9][a-zA-Z0-9\\-]{1,25})/,
        pincode: /^[1-9]{1}[0-9]{5}$/,
        mobile: /^\d{10}$/,
        aadhaar: /^[0-9]{12}$/,
        maskedAadhaar: /^[x]{8}[0-9]{4}$/,
        ifsc: /^[a-zA-z]{4}[0-9a-zA-z]{7}$/,
        bankAccNumber: /^[0-9]{8,16}$/,
    },
    documetnTypes: {
        profilePic: 1,
        companyId: 2,
        pan: 3,
        aadhaarFront: 4,
        aadhaarBack: 5,
        addressProof1: 6,
        addressProof2: 7,
        addressProof3: 8,
        incomeProof1: 9,
        incomeProof2: 10,
        incomeProof3: 11,
        bankStatement1: 12,
        bankStatement2: 13,
        bankStatement3: 14,
        bankStatement4: 15,
        bankStatement5: 16,
        bankStatement6: 17,
        crifReport: 18,
        cibilReport: 19,
        loanAgreement: 20,
        nach: 21,
        signature: 26,
        business_registration_proof: 1500,
        payment_proof: 100
    },
    mandatoryDocumentsMapping: {
        '1': 'profile_picture',
        '3': 'pan_card',
        '6': 'address_proof_1',
        '9': 'last_month_salary_slip',
        '12': 'bank_statement'
    },
    docUploadSuccessStatuses: ['file_creation_success', 'file_updation_success'],
    editingAllowedStates: ['unknown', 'logged_in', 'customer_detail_submit', 'document_submit'],
    adminActions: {      
        login: 'login',             
        logout: 'logout'
    },
    lkAdminActions: {         
        login: 'lendingkart/login',             
        logout: 'logout'
    },
    adminActionStatus: {
        started: 1,
        completed: 2,
        successful: 3,
        failure: 4
    },
    productType: 3,
    deviceType: {
        desktop: 'Desktop',
        mobile: 'Mobile',
        tablet: 'Tablet'
    },
    loanStageCategory: {
        inComplete: ['unknown', 'active_type_1', 'active_type_2', 'active_type_3', 'inactive'],
        complete: ['initial_application_complete'],
        approved: ['initial_pre_approved', 'initial_sanctioned', 'initial_sanctioned_data_complete'],
        disbursed: ['initial_disbursed'],
        postSanctionReject: ['initial_post_sanction_reject'],
        postSanctionDropout: ['initial_post_sanction_dropout', 'initial_post_disbursal_dropout'],
        rejected: ['initial_pre_rejected'],
        duplicate: ['archieved']
    },
    approvedStages: ['initial_pre_approved', 'initial_sanctioned', 'initial_sanctioned_data_complete', 'initial_disbursed', 'foreclose_requested', 'foreclosed', 'new_sanctioned', 'new_disbursed'],
    fileDateTimeFormat: 'ddd_MMM_Do_YYYY_h_mm_ss_a',
    loanStageTextMapping: {
        unknown: 'Complete Data remaining.',
        active_type_1: 'Complete Data remaining.',
        active_type_2: 'Documents Not Uploaded',
        initial_application_complete: 'Application Submitted',
        initial_pre_approved: 'Customer is Approved but yet to download the Upwards app.',
        initial_sanctioned: 'Customer is Approved and downloaded the app.',
        initial_sanctioned_data_complete: 'Customer has submitted additional data on Upwards app.',
        initial_disbursed: 'Loan is disbursed to the customer.',
        initial_pre_rejected: 'Customer is rejected by Upwards.',
        initial_post_sanction_reject: 'Customer is rejected by Upwards.',
        initial_post_sanction_dropout: 'Customer was approved by Upwards but dropped out later.',
        initial_post_disbursal_dropout: 'Customer was approved by Upwards but dropped out later.'
    }
};
export const apiConstants = {
    socialLogin: { url: '/v2/iam/admin/login/' },
    socialLogout: { url: '/v2/iam/admin/logout/' },
    createCustomer: { url: '/v2/customer/loan/data/' },
    updateCustomerData: { url: '/v2/customer/loan/data/update/' },
    displayData: { url: '/v2/common/display_data/' },
    pincode: { url: '/v2/common/pincode/search/' },
    documenetUploadUrl: { url: '/v2/customer/loan/document/' },
    documentUploadStatus: { url: '/v2/customer/loan/document/status/' },
    documentSubmitStateUpdate: { url: '/v2/customer/loan/document/submit/' },
    detailSubmitStateUpdate: { url: '/v2/customer/loan/data/complete/' },
    customerDocumentsStatus: { url: '/v2/customer/loan/documents/detail/' },
    applicationCompleteStateUpdate: { url: '/v2/customer/loan/application_submit/' },
    dataSummary: { url: '/v2/customer/loan/source/data/summary/' },
    referralSummary: { url: '/v2/customer/loan/attribution/data/summary/' },
    searchLeads: { url: '/v2/customer/loan/source/data/search/' },
    associateAttributionSource: { url: '/v2/customer/loan/attribution/data/' },
    adminActionLogging: { url: '/v2/iam/user/action/log/' },
    rejectionReasons: { url: '/v2/customer/loan/rejection_reason/' },
    generateOtp: { url: '/v2/iam/admin/login/otp/' },
    otpLogin: { url: '/v2/iam/admin/login/otp/verify/' },
    dedupe: { url: '/v2/customer/loan/search/' },
    organizationDetails: { url: '/v2/iam/admin/organization/detail/' },
    documentUploadStatusForAffiliate: { url: '/v2/portal/customer/loan/documents/detail/' },
    generateDocumentUploadUrlForAffiliate: { url: '/v2/portal/customer/loan/document/' },
    getDocumentUploadStatusForAffiliate: { url: '/v2/portal/customer/loan/document/status/' },
    documentSubmitStateUpdateForAffiliate: { url: '/v2/portal/customer/loan/document/submit/' },
    documentViewUrl: { url: '/v2/customer/loan/document_data/' },
    documentViewUrlForAffiliate: { url: '/v2/portal/customer/loan/document_data/' },
    applicationCompleteForAffilate: { url: '/v2/portal/customer/loan/application/complete/' },
    attributionLink: {url: '/v2/admin/attribution/links/'},
    underwritingEligibility: {url: `/v2/customer/loan/credit_programs/decision/`},
    documentSubmitStateUpdateBureau: {url: `/v2/customer/loan/banking_data/complete/`},
    detailSubmitStateUpdateBureau: {url: `/v2/customer/loan/data/complete/`},
    underWritingBanking: {url: `/v2/customer/loan/credit_programs/offer/`},
    customerApplicationSubmitAssociate: {url: `/v2/customer/loan/application/complete/`}
};